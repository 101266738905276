import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock4More = (key) => {
    return (
        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen" key={key}>
            <Container.Part background="white" key={key}>
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Vilshofen/vilshofen_bild_0010.jpg"}
                               data-glightbox="title: Gerhard Schröder beim Politischen Aschermittwoch in Köln, 2005; description: .custom-desc1_11; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Vilshofen/vilshofen_bild_0010.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Gerhard Schröder beim Politischen Aschermittwoch in Köln, 2005"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_11">
                                <p>
                                    Auch außerhalb von Bayern organisieren Landesverbände verschiedener Parteien am
                                    Aschermittwoch Veranstaltungen mit führenden Politikern. Die nordrhein-westfälische
                                    SPD etwa lud 2005 in Köln zum Aschermittwoch mit Bundeskanzler Gerhard Schröder ein.
                                    Die Reden am Aschermittwoch befeuern oft tagesaktuelle politische Debatten.
                                </p>
                                <p className="copy">Abb. picture-alliance / Sven Simon</p>
                            </div>
                            <figcaption>
                                Gerhard Schröder beim Politischen Aschermittwoch in Köln, 2005
                                <span className="description">
                                    Auch außerhalb von Bayern organisieren Landesverbände verschiedener Parteien am
                                    Aschermittwoch Veranstaltungen mit führenden Politikern. Die nordrhein-westfälische
                                    SPD etwa lud 2005 in Köln zum Aschermittwoch mit Bundeskanzler Gerhard Schröder ein.
                                    Die Reden am Aschermittwoch befeuern oft tagesaktuelle politische Debatten.</span>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-6">
                        <blockquote>
                            „Denken Sie doch ausnahmsweise noch mal darüber nach […]. Sie erreichen nur, dass die, die
                            im braunen Sumpf im Trüben fischen, sich die Hände reiben.“
                            <span className="author">
                                Gerhard Schröder, Köln 2005
                            </span>
                        </blockquote>
                    </div>
                </Container.Row>
            </Container.Part>
            <Container.Part background="beige" key={key}>
                <Container.Row>
                    <div className="col-6">
                        <blockquote>
                            „Wer sich selbst zum Weißwürstchen macht, darf sich nicht wundern, dass er als solches
                            verspeist wird.“
                            <span className="author">
                                Philipp Rösler, Dingolfing 2012
                            </span>
                        </blockquote>
                    </div>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Vilshofen/vilshofen_bild_0009.jpg"}
                               data-glightbox="title: Philipp Rösler beim Politischen Aschermittwoch der FDP in Dingolfing, 2012; description: .custom-desc1_12; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Vilshofen/vilshofen_bild_0009.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Philipp Rösler beim Politischen Aschermittwoch der FDP in Dingolfing, 2012"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_12">
                                <p>
                                    In seiner Zeit als FDP-Vorsitzender und Vizekanzler kam Philipp Rösler 2012 zum
                                    Politischen Aschermittwoch der Bayern-FDP nach Dingolfing.
                                </p>
                                <p className="copy">Abb. FDP Bayern</p>
                            </div>
                            <figcaption>
                                Philipp Rösler beim Politischen Aschermittwoch der FDP in Dingolfing, 2012
                                <span className="description">
                                    In seiner Zeit als FDP-Vorsitzender und Vizekanzler kam Philipp Rösler 2012 zum
                                    Politischen Aschermittwoch der Bayern-FDP nach Dingolfing.</span>
                            </figcaption>
                        </figure>
                    </div>
                </Container.Row>
            </Container.Part>
            <Container.Part background="white" key={key}>
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Vilshofen/vilshofen_bild_0015.jpg"}
                               data-glightbox="title: Joschka Fischer beim Aschermittwoch von Bündnis 90 / Die Grünen, 2002; description: .custom-desc1_13; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Vilshofen/vilshofen_bild_0015.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Joschka Fischer beim Aschermittwoch von Bündnis 90 / Die Grünen, 2002"/>
                            </a>
                            <figcaption>
                                Joschka Fischer beim Aschermittwoch von Bündnis 90 / Die Grünen, 2002
                                <span className="description">
                                    Seit 1996 veranstalten die Grünen in Biberach in Baden-Württemberg einen Politischen
                                    Aschermittwoch mit bundespolitischer Prominenz. In den ersten Jahren brachten vor
                                    allem Joschka Fischers Auftritte regelmäßig mehr als 1.000 Zuhörer.</span>
                            </figcaption>
                            <div className="glightbox-desc custom-desc1_13">
                                <p>
                                    Seit 1996 veranstalten die Grünen in Biberach in Baden-Württemberg einen Politischen
                                    Aschermittwoch mit bundespolitischer Prominenz. In den ersten Jahren brachten vor
                                    allem Joschka Fischers Auftritte regelmäßig mehr als 1.000 Zuhörer.
                                </p>
                                <p className="copy">Abb. Foto: Johannes Angele</p>
                            </div>
                        </figure>
                    </div>
                    <div className="col-6">
                        <blockquote>
                            „Wenn dieser Bundestagswahlkampf so weitergeht, können die Kabarettisten ihre Arbeit
                            einstellen. Die Politiker überholen sie gnadenlos.“
                            <span className="author">
                                Joschka Fischer, Biberach 2002
                            </span>
                        </blockquote>
                    </div>
                </Container.Row>
            </Container.Part>
            <Container.Part background="beige" key={key}>
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Vilshofen/vilshofen_bild_0013.jpg"}
                               data-glightbox="title: „Aschermittwoch für Dahoam“, Markus Söder beim digitalen Aschermittwoch der CSU, 2021; description: .custom-desc1_13; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Vilshofen/vilshofen_bild_0013.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="„Aschermittwoch für Dahoam“, Markus Söder beim digitalen Aschermittwoch der CSU, 2021"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_13">
                                <p>
                                    Der Aschermittwoch gehört mittlerweile fest zum politischen Kalender. Besonders in
                                    Wahlkampfzeiten wollen es die Parteien sich nicht nehmen lassen, mit den Reden
                                    Positionen zu setzen. Digitale Formate und die Einbindung von Social Media sollten
                                    2021 einige der aufgrund der Coronapandemie nicht möglichen Veranstaltungen
                                    ersetzen.
                                </p>
                                <p className="copy">Abb. CSU Bayern</p>
                            </div>
                            <figcaption>
                                „Aschermittwoch für Dahoam“, Markus Söder beim digitalen Aschermittwoch der CSU, 2021
                                <span className="description">
                                    Der Aschermittwoch gehört mittlerweile fest zum politischen Kalender. Besonders in
                                    Wahlkampfzeiten wollen es die Parteien sich nicht nehmen lassen, mit den Reden
                                    Positionen zu setzen. Digitale Formate und die Einbindung von Social Media sollten
                                    2021 einige der aufgrund der Coronapandemie nicht möglichen Veranstaltungen
                                    ersetzen.</span>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Vilshofen/vilshofen_bild_0014.jpg"}
                               data-glightbox="title: Tagesschau am 14. Februar 2018; description: .custom-desc1_14; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Vilshofen/vilshofen_bild_0014.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Tagesschau am 14. Februar 2018"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_14">
                                <p>
                                    Das große bundesweite Interesse zeigt sich auch in den Medien. Alljährlich berichtet
                                    etwa die Tagesschau von den verschiedenen Veranstaltungen und den Reden prominenter
                                    Politiker.
                                </p>
                                <p className="copy">Abb. ARD-aktuell, 2018</p>
                            </div>
                            <figcaption>
                                Tagesschau am 14. Februar 2018
                                <span className="description">
                                Das große bundesweite Interesse zeigt sich auch in den Medien. Alljährlich berichtet
                                etwa die Tagesschau von den verschiedenen Veranstaltungen und den Reden prominenter
                                Politiker.</span>
                            </figcaption>
                        </figure>
                    </div>
                </Container.Row>
            </Container.Part>
        </Container.More>
    )
}

export default TextBlock4More
