import * as React from 'react'

const Page = () => {
    return (
        <>
            <h3>Transkription zu:<br/>Ankündigung der Aschermittwochskundgebung, 1919</h3>
            <p>
                Vilshofener Tagblatt<br/>
                Dienstag, den 4. März 1919<br/>
            </p>
            <h4>Bayer. Bauernbund Vilshofen.</h4>
            <p>
                Am Aschermittwoch, 5. März, vormittags 10 Uhr findet bei Knollmüller<br/>
                Gasthof zum „Goldenen Ochsen“ in Vilshofen:
            </p>
            <h4>Vertrauensmänner-Versammlung</h4>
            <p>
                für den Landtagswahlkreis Vilshofen-Arnstorf statt.<br/>
                Mittags 12 Uhr im Wieninger-Saal: Große
            </p>
            <h4>Volksversammlung</h4>
            <p>
                Zu welcher als Redner erscheinen die Herren:<br/>
                Reichstagsabgeordneter Bachmeier, Landtagsabgeordnete Matzeder und Klarhauser,<br/>
                Redakteur Rübler, Landau a. I [Landau an der Isar].<br/>
                Männer und Frauen sind zu zahlreichem Besuche eingeladen. Freie Aussprache.<br/>
                Steiger, Wahlkreisvorsitzender, Butzenberger, Bezirksobmann,<br/>
                Leitner, Delegierter des A.- und B.-Rates [Arbeiter- und Bauernrates].
            </p>
        </>
    )
}

export default Page;
