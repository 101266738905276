import Headerimage from "./Headerimage";
import Novel from "./Novel";
import TextBlock1Blue from "./TextBlock1Blue";
import TextBlock2White from "./TextBlock2White";
import TextBlock3Beige from "./TextBlock3Beige";
import TextBlock4More from "./TextBlock4More";
import Video from "./Video";

const Parts = [
    Headerimage,
    TextBlock1Blue,
    Novel,
    TextBlock2White,
    TextBlock3Beige,
    TextBlock4More,
    Video,
];

export {Parts};
