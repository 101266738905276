import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock2White = (key, ortName) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Vilshofen/vilshofen_bild_0007.jpg"}
                           data-glightbox="title: Politischer Aschermittwoch im Wolferstetter Keller in Vilshofen in den 1950er-Jahren; description: .custom-desc1_4; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Vilshofen/vilshofen_bild_0007.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Politischer Aschermittwoch im Wolferstetter Keller in Vilshofen in den 1950er-Jahren"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_4">
                            <p>
                                Von den 1950er-Jahren bis in die 1970er-Jahre fand die Aschermittwochsveranstaltung der
                                CSU meist im Wolferstetter Keller statt.
                            </p>
                            <p className="copy">Abb. Stadtarchiv Vilshofen, Archivsammlung Ludwig Maier, Foto: P.
                                Fuchs</p>
                        </div>
                        <figcaption>
                            Politischer Aschermittwoch im Wolferstetter Keller in Vilshofen in den 1950er-Jahren
                            <span className="description">
                                Von den 1950er-Jahren bis in die 1970er-Jahre fand die Aschermittwochsveranstaltung der
                                CSU meist im Wolferstetter Keller statt.
                                </span>
                        </figcaption>
                    </figure>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Vilshofen/vilshofen_bild_0006.jpg"}
                           data-glightbox="title: Veranstaltung der SPD am Aschermittwoch im Wolferstetter Keller, 1976; description: .custom-desc1_7; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Vilshofen/vilshofen_bild_0006.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Veranstaltung der SPD am Aschermittwoch im Wolferstetter Keller, 1976"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_7">
                            <p className="copy">Abb. Stadtarchiv Vilshofen, Sammlung Ludwig Maier</p>
                        </div>
                        <figcaption>
                            Veranstaltung der SPD am Aschermittwoch im Wolferstetter Keller, 1976
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Vilshofen/vilshofen_bild_0008.jpg"}
                           data-glightbox="title: Plakatwand mit Ankündigungen für Aschermittwochsveranstaltungen, 1953; description: .custom-desc1_5; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Vilshofen/vilshofen_bild_0008.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Plakatwand mit Ankündigungen für Aschermittwochsveranstaltungen, 1953"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_5">
                            <p>
                                Ab 1948 trat für die Bayernpartei häufig der Landtagsabgeordnete und Parteivorsitzende
                                Josef Baumgartner als Redner am Aschermittwoch auf.
                            </p>
                            <p className="copy">Abb. picture alliance / Willi Antonowitz</p>
                        </div>
                        <figcaption>
                            Plakatwand mit Ankündigungen für Aschermittwochsveranstaltungen, 1953
                            <span className="description">
                                Ab 1948 trat für die Bayernpartei häufig der Landtagsabgeordnete und Parteivorsitzende
                                Josef Baumgartner als Redner am Aschermittwoch auf.
                                </span>
                        </figcaption>
                    </figure>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Vilshofen/vilshofen_bild_0005.jpg"}
                           data-glightbox="title: Franz Josef Strauß während seiner Rede am Politischen Aschermittwoch im Wolferstetter Keller, 1970; description: .custom-desc1_6; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Vilshofen/vilshofen_bild_0005.jpg" width={335} layout="fixed" placeholder="none"  alt="Franz Josef Strauß während seiner Rede am Politischen Aschermittwoch im Wolferstetter
                                Keller, 1970"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_6">
                            <p className="copy">Abb. Stadtarchiv Vilshofen</p>
                        </div>
                        <figcaption>
                            Franz Josef Strauß während seiner Rede am Politischen Aschermittwoch im Wolferstetter
                            Keller, 1970
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock2White
