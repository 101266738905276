import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock3Beige = (key, ortName) => {
    return (
        <Container.Part background="beige" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>Der Politische Aschermittwoch – eine Tradition aus Vilshofen erobert Deutschland</h2>
                </div>
            </Container.Row>
            <Container.Row>
                <div className="col-6">
                    <blockquote>
                        „Es ist die Aufgabe von Politik,
                        zu dienen und nicht rumzumosern.“
                        <span className="author">
                                Angela Merkel, Demmin 2018
                            </span>
                    </blockquote>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Vilshofen/vilshofen_bild_0012.jpg"}
                           data-glightbox="title: Angela Merkel beim Politischen Aschermittwoch in Demmin, 2018; description: .custom-desc1_9; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Vilshofen/vilshofen_bild_0012.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Angela Merkel beim Politischen Aschermittwoch in Demmin, 2018"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_9">
                            <p>
                                Angela Merkel ist am Aschermittwoch regelmäßig in ihrem Landesverband
                                Mecklenburg-Vorpommern in Demmin zu Gast. In einer zum Bierzelt umfunktionierten
                                Tennishalle findet hier der größte Aschermittwoch der CDU mit rund 2.000 Gästen statt.
                            </p>
                            <p className="copy">Abb. picture alliance / dpa | Christian Charisius</p>
                        </div>
                        <figcaption>
                            Angela Merkel beim Politischen Aschermittwoch in Demmin, 2018
                            <span className="description">
                                Angela Merkel ist am Aschermittwoch regelmäßig in ihrem Landesverband
                                Mecklenburg-Vorpommern in Demmin zu Gast. In einer zum Bierzelt umfunktionierten
                                Tennishalle findet hier der größte Aschermittwoch der CDU mit rund 2.000 Gästen statt.</span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock3Beige
