import * as React from 'react'
import * as Container from "../../container/container";
import {Pages} from "../vilshofen/facsimile";

const TextBlock1Blue = (key) => {
    return (
        <Container.Part background="blue" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>„Innerlich wirkte das Festbier,<br/><span>&nbsp;</span> äußerlich das Diskutieren.“</h2>
                    <div className="inner-container">
                        <p>Alljährlich steht am Aschermittwoch der rhetorische Schlagabtausch der Parteien im Interesse
                            der Öffentlichkeit. Als „Geburtsort“ des Politischen Aschermittwochs gilt
                            Vilshofen in Niederbayern. Schon im 16. Jahrhundert gab es hier am Aschermittwoch einen
                            Viehmarkt.
                        </p>
                        <p>
                            1919 lud der Bayerische Bauernbund erstmals am Aschermittwoch zu einer politischen
                            Versammlung ein. In den folgenden Jahren fanden unregelmäßig von verschiedenen Akteuren
                            organisierte Kundgebungen statt, beispielsweise Veranstaltungen vom Christlichen
                            Bauernverein oder dem Bauern- und Mittelstandsbund.
                            Von 1933 bis 1937 nutzte die NSDAP diese Tradition, ließ dann jedoch wieder davon ab. Es
                            blieb der Viehmarkt.
                        </p>

                        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen">

                            <p>
                                In der Nachkriegszeit gab es ab 1948 wieder politische Kundgebungen, die zunächst vor
                                allem von der heftigen Auseinandersetzung zwischen CSU und Bayernpartei geprägt waren.
                                Ab 1953 zog die CSU mit Franz Josef Strauß als zugkräftigem Redner die Aufmerksamkeit
                                auf sich. Der zum traditionellen Austragungsort gewordene Wolferstetter Keller konnte
                                die Besucherinnen und Besucher bald nicht mehr fassen. 1975 verlegte die CSU ihre
                                Veranstaltung in die Passauer Nibelungenhalle. Im Wolferstetter Keller versammelte sich
                                fortan die SPD und bezog Position gegen die CSU.
                            </p>
                            <p>
                                Im Laufe der Zeit nutzten auch andere politische Parteien die mediale Aufmerksamkeit für
                                den Politischen Aschermittwoch, der sich von einem lokalen Ereignis zu einem politischen
                                Schlagabtausch und Medienevent mit landes- und bundesweiter Ausstrahlung gewandelt hat.
                            </p>
                            <br/>
                        </Container.More>
                        <div className="center">
                            <figure className="lightbox">
                                <Container.Facsimile
                                    pages={Pages['06_03']}
                                    img="/images/Vilshofen/vilshofen_bild_0016.jpg"
                                    alttext="Ankündigung der Aschermittwochskundgebung, 1919">
                                    <figcaption>
                                        Ankündigung der Aschermittwochskundgebung, 1919
                                        <span className="description">
                                        Der Bayerische Bauernbund lud am 5. März 1919 vormittags zu einer
                                        Vertrauensmänner-Versammlung für den Wahlkreis Vilshofen-Arnstorf und nachmittags
                                        zu einer Volksversammlung. Der Bayerische Bauernbund hatte bei den Landtagswahlen
                                        im Januar 1919 in Niederbayern 30,8 Prozent der Stimmen erhalten.
                                    </span>
                                        <span
                                            className="copy">Abb. Stadtarchiv Vilshofen, Vilshofener Tagblatt, 2. März 1919</span>
                                    </figcaption>
                                </Container.Facsimile>
                            </figure>
                        </div>
                    </div>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock1Blue
