import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Headerimage = (key, ortName) => {
    return (
        <Container.HeaderPart background="Vilshofen" key={key}>
            <div className="headline">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>1919<br/>
                                Vilshofen<br/>
                                an der Donau </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="box">
                            <ul>
                                <li>Parteien</li>
                                <li>Politische Kontroversen</li>
                            </ul>
                            <p> In Vilshofen fand 1919 der erste
                                Politische Aschermittwoch statt.</p>
                            <p> Die Tradition der oft deftigen Wortgefechte und politischen Kontroversen zwischen den
                                Parteien entwickelte sich immer mehr zu einem Polit-Event von landes- und dann
                                bundesweiter Ausstrahlung.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="image-row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Vilshofen/vilshofen_bild_0011.jpg"}
                                   data-glightbox="title: Wieninger Konzertsaal in Vilshofen, 1920er-Jahre; description: .custom-desc1_2; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Vilshofen/vilshofen_bild_0011.jpg" width={335} layout="fixed" placeholder="none"
                                                 alt="Wieninger Konzertsaal in Vilshofen, 1920er-Jahre"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_2">
                                    <p>
                                        1919 rief der Bayerische Bauernbund zu einer Vollversammlung in Vilshofen im
                                        Wieninger Konzertsaal auf. Als Redner für den Bauernbund traten der Bauernrat
                                        Johann Leitner und der Landtagsabgeordnete Joseph Klarhauser auf.
                                    </p>
                                    <p className="copy">Abb. Stadtarchiv Vilshofen, Sammlung Rudolf Drasch</p>
                                </div>
                                <figcaption>
                                    Wieninger Konzertsaal in Vilshofen, 1920er-Jahre
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Vilshofen/vilshofen_bild_0004.jpg"}
                                   data-glightbox="title: Viehmarkt in Vilshofen, Niederbayern, 1937; description: .custom-desc1_3; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Vilshofen/vilshofen_bild_0004.jpg" width={335} layout="fixed" placeholder="none"
                                                 alt="Viehmarkt in Vilshofen, Niederbayern, 1937"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_3">
                                    <p>
                                        Der traditionell am Aschermittwoch in Vilshofen stattfindende Viehmarkt erlangte
                                        Ende des 19. Jahrhunderts zunehmende Bedeutung. Besucher aus der ganzen Umgebung
                                        kamen nach Vilshofen. Bis in die 1960er-Jahre wurde hier Vieh gehandelt. Danach
                                        blieben vor allem die Käse- und Fischstände.
                                    </p>
                                    <p className="copy">Abb. Stadtarchiv Vilshofen</p>
                                </div>
                                <figcaption>
                                    Viehmarkt in Vilshofen, Niederbayern, 1937
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </Container.HeaderPart>
    )
}

export default Headerimage
